<template>
  <div class="sewage">
    <span class="module-btn" @click="checkModule">模型</span>
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @selCb="selCb"
      ref="table"
      @page-size-change="pageSizeChange"
      @foucesed="foucesed"
    >
    </x-table>

    <select-farm
      v-model="selModal"
      :farmId="farmId"
      :options="options"
      @sel-finish="selFinish"
    ></select-farm>

    <Modal v-model="moduleShow" title="排污量模型" :width="1658" footer-hide>
      <div class="model-box">
        <div class="sewage-disposal-model">
          <div class="title-box">
            <p class="title">污水排放模型</p>
            <span class="iconfont icon">&#xe6e4;</span>
          </div>
          <div class="content-box">
            <div class="content-left">
              <div class="left-item item-a">
                <p class="count">
                  <span>{{ drain ? drain.maxValue : "--" }}</span
                  >m³
                </p>
                <p class="text">今日最高允许排水</p>
              </div>
              <div class="left-item item-b">
                <p class="count">
                  <span>{{ drain ? drain.maxValue : "--" }}</span
                  >m³
                </p>
                <p class="text">今日已排水(实时)</p>
              </div>
            </div>
            <div class="content-right">
              <div class="formula-box">
                <span class="result">W</span>
                <span>=</span>
                <p>
                  <span>C</span>
                  <span class="line"></span>
                  <span>Φ</span>
                </p>
                <span>×</span>
                <span>L</span>
              </div>
              <div class="annotation-box">
                <p>
                  <span class="a-item"
                    >W：最高允许排水量（<span class="num">{{
                      drain ? drain.maxValue : "--"
                    }}</span
                    >）</span
                  >
                  <span class="a-item"
                    >C：存栏数量（<span class="num">{{
                      drain ? drain.num : "--"
                    }}</span
                    >）</span
                  >
                </p>
                <p>
                  <span class="a-item"
                    >Φ：单位系数（<span class="num">{{
                      drain ? drain.productValue : "--"
                    }}</span
                    >）</span
                  >
                  <span class="a-item"
                    >L：单位标准值（<span class="num">{{
                      drain ? drain.standardValue + drain.seasonName : "--"
                    }}</span
                    >）</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="manure-craft">
          <div
            v-show="manureCraftImg"
            id="svgaplayer"
            style="width: 100%; height: 100%"
          >
            <!-- <img :src="manureCraftImg" alt="" /> -->
          </div>
          <div
            v-show="!manureCraftImg"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            该场未上报工艺流程
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as SVGA from "svgaplayerweb";
import selectFarm from "@/components/selectFarm";

export default {
  name: "",
  components: {
    selectFarm,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖场名称",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "所属养殖户",
            minWidth: 110,
            key: "createUserName",
          },
          {
            title: "处理工艺",
            minWidth: 100,
            key: "manureProcessName",
          },
          {
            title: "存栏数量",
            minWidth: 100,
            key: "num",
          },
          {
            title: "当日最高允许排水量(m³)",
            minWidth: 190,
            key: "maxValue",
          },
          // {
          //   title: "当前品种单位系数",
          //   minWidth: 160,
          //   key: 'productValue'
          // },
          // {
          //   title: "单位标准值",
          //   minWidth: 110,
          //   key: 'standardValue'
          // },
          {
            title: "记录时间",
            minWidth: 180,
            key: "createTime",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 13,
        total: 0,
      },
      search_data: {},
      farmScene: [],
      category: [],
      manureCraftImg: "",
      drain: null,
      // svga动画播放插件
      player: null,
      farmId: "",
      farmName: "",
      selModal: false,
      options: {
        siteType: "2",
      },
      moduleShow: false
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 200,
          searchBtnHide: true,
          filterBox: [
            // {
            //   conditionName: "养殖场",
            //   component: "select",
            //   data: this.farmScene,
            //   field: "farmId",
            //   defaultValue:
            //     this.farmScene.length > 0 ? this.farmScene[0].id : "",
            //   parameterField: "id",
            //   showField: "name",
            //   isCallback: true,
            //   mark: "1",
            // },
            {
              conditionName: "养殖场",
              component: "input",
              field: "farmName",
              defaultValue: this.farmName,
              isFocus: true,
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  watch: {
    manureCraftImg(url) {
      if (!url) {
        this.player && this.player.clear();
      } else {
        this.initSVGA(url);
      }
    },
  },
  methods: {
    checkModule() {
      this.moduleShow = true
    },
    foucesed() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.farmId = farm.id;
      this.farmName = farm.name;
      this.search_data.farmId = farm.id + "";
      this.getDrain();
      this.getList();
      if (farm.craft) {
        this.$post(
          this.$api.SYS_CATEGORY.VIEW.replace("{id}", farm.craft)
        ).then((res) => {
          this.manureCraftImg = res?.description ?? "";
        });
      } else {
        this.manureCraftImg = "";
      }
      this.$nextTick(() => {
        this.$refs.table.setDefaults();
      });
      this.selModal = false;
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = {
        ...data,
        farmId: data.farmId + "",
      };
      this.getList();
      this.getDrain();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    selCb(val, mark) {
      if (mark == "1") {
        let n = this.farmScene.filter((item) => item.id == val)[0];
        if (n) {
          let m = this.category.filter((item) => item.id == n.manureProcess)[0];
          this.search_data.farmId = val + "";
          this.getDrain();
          this.getList();
          if (n.craft) {
            this.$post(
              this.$api.SYS_CATEGORY.VIEW.replace("{id}", n.craft)
            ).then((res) => {
              this.manureCraftImg = res?.description ?? "";
            });
          } else {
            this.manureCraftImg = "";
          }
          // this.manureCraftImg = this.getImgUrl(m.servicePath, m.img);
        }
      }
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.MANURE_DRAIN_MODEL.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    getDrain() {
      this.$post(this.$api.MANURE_DRAIN_MODEL.DRAIN, {
        ...this.search_data,
      }).then((res) => {
        this.drain = res[0];
      });
    },

    getBreedArea() {
      this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST, {
        siteType: "2",
      }).then((res) => {
        this.farmScene = res.list;
        if (res.list.length > 0) {
          this.$nextTick(() => {
            this.$refs.table.setDefaults();
          });
        }
        // this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList");
      });
    },
    getCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "25",
      }).then((res) => {
        this.category = res.list;
      });
    },
    // 初始化SVGA插件
    initSVGA(url) {
      if (!this.player) this.player = new SVGA.Player("#svgaplayer");
      let parser = new SVGA.Parser("#svgaplayer");
      parser.load(url, (videoItem) => {
        this.player.setVideoItem(videoItem);
        this.player.startAnimation();
      });
    },
  },
  mounted() {
    this.getList();
    // this.getBreedArea();
    this.getCategory();
    // this.initSVGA();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>